















import Vue from 'vue'
import { Component } from 'vue-property-decorator'

import PageTitle from '@/components/PageTitle.vue'

import BotProperties from 'piramis-base-components/src/components/BotProperties/BotPropertiesAndLabels.vue'
import { ExportToCsv } from 'export-to-csv'
import {
  Property,
  PropertyFieldType
} from 'piramis-base-components/src/components/BotProperties/components/Properties/types'

@Component({
  components: {
    PageTitle,
    BotProperties,
  }
})
export default class NewBotProperties extends Vue {
  isLoading = false

  get properties(): Array<Property> {
    if (this.$store.getters.properties) {
      return this.$store.getters.properties.filter((p) => p.group === 'custom')
    }
    return []
  }

  exportToCsv(target: string, items: Array<any>): void {
    const options = {
      'fieldSeparator': ';',
      'quoteStrings': '"',
      'decimalSeparator': '.',
      'showLabels': true,
      'showTitle': true,
      'filename': this.$t(`${ target }_csv_table`, { bot: this.$store.state.boardsState.activeBoard!.title }).toString(),
      'title': this.$t(`${ target }_csv_table`, { bot: this.$store.state.boardsState.activeBoard!.title }).toString(),
      'useTextFile': false,
      'useBom': true,
      'useKeysAsHeaders': true,
      'headers': undefined,
    }

    new ExportToCsv(options).generateCsv(items)
  }

  createLabel(label: string): Promise<{ id: number, name: string }> {
    return new Promise(resolve => {
      this.$store.dispatch('createLabel', label)
        .then(({ index }) => {
          resolve({
            id: index,
            name: label
          })
        })
    })
  }

  createProperty(name: string, type: PropertyFieldType): Promise<{ id: number, name: string, type: PropertyFieldType }> {
    return new Promise(resolve => {
      this.$store.dispatch('createProperty', { name, type })
        .then((id) => resolve({ id, name, type }))
    })
  }

  saveConfig(properties: Array<Property>, labels: { [key: number]: string }): Promise<void> {
    this.isLoading = true

    this.$store.commit('set_properties_to_store', properties)
    this.$store.commit('set_labels_to_store', labels)

    return new Promise(resolve => {
      this.$store.dispatch('savePostBoardConfig')
        .then(() => resolve())
        .finally(() => this.isLoading = false)
    })
  }
}
